.section-service {
  align-items: center;
  background-image: url("/src/assets/gradient_2_desktop.png");
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  display: flex;
  gap: 1vw;
  padding: 5.2vw 6.5vw;
  height: 1008px;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  max-width: 2460px;
  max-height: 1080px;
  margin: 0 auto;
  position: relative;
}

.section-service .content {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.section-service .heading-instance {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.section-service .design-component-instance-node {
  color: var(--Neutral-White, #fff);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Mulish;
  font-size: 67px;
  font-style: normal;
  font-weight: 900;
  line-height: 72px;
  letter-spacing: -1px;
  text-align: left;
}

.section-service .img {
  color: var(--Neutral-White, #fff);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Mulish;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -1px;
  text-align: left;
  width: 85%;
}

.section-service .button-instance {
  padding: 0.8vw 1.2vw;
  gap: 0.4vw;
  border-radius: 0.2vw;
  background: var(--Neutral-950, #2d3047);
  color: #fff;
  font-family: "Space Mono";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  max-width: 90%;
  cursor: pointer;
  margin-right: 30vw;
}

.section-service .button-instance:hover {
  background: var(--Neutral-950, #2d3047);
}

.section-service .image {
  flex: 1;
  flex-grow: 1;
  height: 798.72px;
  position: relative;
}

.section-service .overlap-group {
  height: 798.72px;
  position: relative;
  width: 579.84px;
}

.section-service .image-2 {
  width: 463.011px;
  height: 580px;
  flex-shrink: 0;
  left: 0;
  border-radius: 8px;
  object-fit: cover;
  position: absolute;
  top: 20px;
}

.section-service .image-3 {
  width: 513.339px;
  height: 320px;
  object-fit: cover;
  position: absolute;
  top: 450.4px;
  flex-shrink: 0;
  border-radius: 8px;
  z-index: 2;
  left: 100px;
}

@media (max-width: 768px) {
  .section-service {
    padding: 10vw 2vw;
    flex-direction: column;
    height: auto;
    background-size: fill;
    background-position: center;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
  }

  .section-service .content {
    margin: 0;
    align-items: flex-start;
    text-align: left;
    width: 100%;
    color: var(--Neutral-White, #fff);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Mulish;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -1px;
  }

  .section-service .design-component-instance-node {
    font-size: 6vw;
    text-align: left;
    width: 90%;
    margin-left: -1vw;
    max-width: 100%;
  }

  .section-service .img {
    font-size: 4vw;
    text-align: left;
    width: 90%;
    margin-bottom: 5vw;
    max-width: 100%;
    margin-left: -1vw;
  }

  .section-service .button-instance {
    font-size: 3.5vw;
    padding: 3vw 5vw;
    margin-bottom: 5vw;
    max-width: 100%;
    margin-left: 5vw;
  }

  .section-service .image {
    height: auto;
    margin-top: 5vw;
  }

  .section-service .overlap-group {
    width: 80vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: -5vw;
  }

  .section-service .image-2,
  .section-service .image-3 {
    flex-shrink: 0;
    margin-bottom: 5vw;
    border-radius: 0.4vw;
    object-fit: cover;
    align-items: center;
  }

  .section-service .image-2 {
    width: 60vw;
    height: 75vw;
    z-index: 1;
    position: relative;
    left: 5vw;
  }

  .section-service .image-3 {
    width: 60vw;
    height: 40vw;
    left: 7.5vw;
    top: -20vw;
    z-index: 2;
    position: relative;
  }
}

@media (max-width: 480px) {
  .section-service {
    padding: 60px 24px;
    background-image: url("/src/assets/gradient_2_mobile.png");
    background-size: fill;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
  }

  .section-service .design-component-instance-node {
    font-size: 7vw;
    line-height: 8vw;
    width: 100%;
    margin-bottom: 6vw;
    max-width: 100%;
    color: var(--Neutral-White, #fff);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Mulish;
    font-size: 50px;
    font-style: normal;
    font-weight: 900;
    line-height: 60px;
    letter-spacing: -1px;
  }

  .section-service .img {
    font-size: 5vw;
    line-height: 6vw;
    width: 100%;
    margin-bottom: 6vw;
    max-width: 100%;
    color: var(--Neutral-White, #fff);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Mulish;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -1px;
  }

  .section-service .button-instance {
    font-size: 3.33vw;
    padding: 1.25vw 2.5vw;
    margin-bottom: 6vw;
    margin-left: 5vw;
  }

  .section-service .image {
    margin-top: 4vw;
  }

  .section-service .overlap-group {
    width: 80vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: -5vw;
  }

  .section-service .image-2,
  .section-service .image-3 {
    flex-shrink: 0;
    margin-bottom: 4vw;
    border-radius: 0.4vw;
    object-fit: cover;
    align-items: center;
  }

  .section-service .image-2 {
    width: 60vw;
    height: 75vw;
    z-index: 1;
    position: relative;
    left: -16vw;
  }

  .section-service .image-3 {
    width: 75vw;
    height: 40vw;
    left: 0vw;
    top: -20vw;
    z-index: 2;
    position: relative;
    margin-bottom: -6vw;
  }
}
