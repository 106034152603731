/* App.css */

/* Color Palette */
:root {
  --deep-blue: #000080;
  --sky-blue: #87ceeb;
  --red: #ff0000;
  --white: #ffffff;
}

/* Typography */
body {
  font-family: "Avenir Next", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Avenir Next Heavy", sans-serif;
}

.bold {
  font-family: "Avenir Next Bold", sans-serif;
}

.regular {
  font-family: "Avenir Next Regular", sans-serif;
}

.space-mono-bold-italic {
  font-family: "Space Mono", monospace;
  font-weight: bold;
  font-style: italic;
}

.space-mono-italic {
  font-family: "Space Mono", monospace;
  font-style: italic;
}

.mulish-black {
  font-family: "Mulish", sans-serif;
  font-weight: 900;
}

.mulish-extra-bold {
  font-family: "Mulish", sans-serif;
  font-weight: 800;
}

.mulish-regular {
  font-family: "Mulish", sans-serif;
  font-weight: 400;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.section {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  box-sizing: border-box;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
