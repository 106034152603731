.projects-section {
  width: 100%;
  padding: 104px 130px;
  text-align: center;
  box-sizing: border-box;
  background-size: cover;
  max-width: 2460px;
  max-height: 1080px;
  margin: 0 auto;
}

.projects-section h2 {
  font-family: "Mulish", sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  color: #2d3047;
  margin: 0 0 24px 0;
  text-shadow: none;
}

.projects-section h3 {
  color: var(--Neutral-950, #2d3047);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Space Mono";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.projects-section .headline {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  color: var(--Neutral-950, #2d3047);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* Headline/XXXXL/Regular */
  font-family: Mulish;
  font-size: 89px;
  font-style: normal;
  font-weight: 700;
  line-height: 89px;
  /* 100% */
  letter-spacing: -2px;
}

.projects-section .headline span {
  align-items: center;
  justify-content: center;
}

.projects-section .headline .man-photo,
.projects-section .headline .city-photo,
.projects-section .headline .woman-photo {
  border-radius: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.projects-section .headline .man-photo {
  width: 240px;
  height: 98px;
  background: url("../../assets/results.png") lightgray 50% / cover no-repeat;
}

.projects-section .headline .city-photo {
  width: 120px;
  height: 98px;
  background: url("../../assets/coming.png") lightgray 50% / cover no-repeat;
}

.projects-section .headline .woman-photo {
  width: 240px;
  height: 98px;
  background: url("../../assets/real.png") lightgray 50% / cover no-repeat;
}

.projects-section p {
  color: var(--Neutral-950, #2d3047);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  padding-top: 24px;
  text-align: left;
}

.projects-section .redline {
  color: var(--Primary-600, #d62c2f);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  text-decoration-line: underline;
}

.projects-section .about-button {
  font-family: "Mulish", sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  background-color: #d62c2f;
  border: none;
  padding: 12px 24px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 24px;
}

.projects-section .about-button:hover {
  background-color: #b7282b;
}

@media (max-width: 480px) {
  .projects-section {
    padding: 104px 24px 104px 24px;
  }

  .projects-section h2 {
    color: var(--Neutral-950, #2d3047);
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Mulish;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -1px;
  }

  .projects-section .headline {
    color: var(--Neutral-950, #2d3047);
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Mulish;
    font-size: 11.5vw;
    font-style: normal;
    font-weight: 000;
    line-height: 1.2;
    letter-spacing: -0.5px;
    justify-content: center;
    gap: 10px;
    margin-bottom: 30px;
  }

  .projects-section .headline .man-photo {
    width: 120px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 100px;
    background: url("../../assets/results_mobile.png") lightgray 50% / cover no-repeat;
  }

  .projects-section .headline .woman-photo {
    width: 120px;
    height: 60px;
    border-radius: 100px;
    background: url("../../assets/real_mobile.png") lightgray 50% / cover no-repeat;
  }

  .projects-section .headline .city-photo {
    width: 80px;
    height: 60px;
    background: url("../../assets/coming_mobile.png") lightgray 50% / cover no-repeat;
  }

  .projects-section p {
    color: var(--Neutral-950, #2d3047);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Mulish;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .projects-section .redline {
    color: var(--Primary-600, #d62c2f);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Mulish;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-decoration-line: underline;
  }

  .projects-section .about-button {
    font-size: 16px;
    padding: 8px 16px;
  }
}