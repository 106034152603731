.project-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
  max-width: 2460px;
  margin: 0 auto;
  
}

.project {
  background-image: url("/src/assets/blurry-gradient-haikei_desktop.png");
  background-position: center;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
  padding: 104px 130px;
  max-width: 2460px;
  max-height: 1080px;
  margin: 0 auto;
  background-size: fill;
  box-sizing: border-box;
}

.project-content {
  max-width: 70%;
  text-align: left;
}

.project h2 {
  color: var(--Neutral-950, #2d3047);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Mulish;
  font-size: 67px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: -1px;
  margin-bottom: 80px;
}

.project p {
  color: var(--Neutral-950, #2d3047);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 60px;
}

.contact-button {
  position: absolute;
  right: 120px;
  bottom: 210px;
  color: var(--Neutral-White, #fff);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Space Mono";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  border: none;
  border-radius: 4px;
  background: var(--Primary-600, #d62c2f);
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  max-width: 50%;
}

.contact-button:hover {
  background-color: #b7282b;
}

@media (max-width: 1024px) {
  .contact-button {
    right: 50px;
    bottom: 50px;
    font-size: 18px;
    padding: 14px 20px;
  }

  .project {
    padding: 40px 20px;
    background-size: contain;
  }

  .project h2 {
    font-size: 50px;
    line-height: 60px;
  }

  .project p {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 480px) {
  .project {
    padding: 104px 24px 104px 24px;
    background: url("/src/assets/blurry-gradient-haikei_mobile.png");;
    background-size: fill;
    background-position: center;
  }

  .project h2 {
    color: var(--Neutral-950, #2d3047);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Mulish;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1px;
  }

  .project p {
    color: var(--Neutral-950, #2d3047);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 40px;
  }

  .contact-button {
    justify-content: flex-start;
    left: 24px;
    align-items: center;
    position: absolute;
    font-size: 16px;
    padding: 12px 20px;
  }
}
