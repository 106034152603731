.footer {
  background-color: #2d3047;
  width: 100%;
  padding-right: 130px;
  padding-left: 130px;
  padding-top: 80px;
  padding-bottom: 64px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 24px;
  box-sizing: border-box;
  max-width: 2460px;
  max-height: 1080px;
  margin: 0 auto;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin: 0 auto;
}

.footer-logo-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-logo {
  width: 32px;
  height: 40.944px;
  position: relative; 
}

.footer-tagline {
  color: #8fa3cb;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.footer-nav {
  color: var(--Neutral-White, #fff);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Space Mono";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  position: relative;
  width: 50%;
}

.footer-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-nav li {
  margin-bottom: 24px;
}

.footer-nav li:last-child {
  margin-bottom: 0;
}

.footer-nav a {
  color: #ffffff;
  text-decoration: none;
  font-family: "Space Mono";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.footer-bottom-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px; 
}

.footer-privacy {
  color: var(--Neutral-White, #fff);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Space Mono";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  justify-content: flex-start;
  gap: 40px;
  flex-wrap: wrap;
}

.footer-privacy a {
  color: #ffffff;
  text-decoration: none;
}

.footer-copyright {
  font-size: 14px;
  color: #8fa3cb;
}

.footer-copyright-desktop {
  display: inline;
}

.footer-copyright-mobile {
  display: none;
}

@media (max-width: 768px) {
  .footer {
    padding: 10vw 4vw;
    align-items: flex-start;
  }

  .footer-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-logo-section {
    align-items: flex-start;
    text-align: left;
  }

  .footer-logo {
    width: 4.17vw;
    height: 5.33vw;
  }

  .footer-tagline,
  .footer-nav {
    font-size: 2.08vw;
    line-height: 3.13vw;
  }

  .footer-nav ul {
    align-items: flex-start;
  }

  .footer-nav li {
    margin-bottom: 3.13vw;
  }

  .footer-bottom-section {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .footer-privacy {
    font-size: 2.08vw;
    line-height: 3.13vw;
    gap: 5.21vw;
  }

  .footer-copyright {
    font-size: 2.08vw;
    line-height: 3.13vw;
  }

  .footer-copyright-desktop {
    display: none;
  }

  .footer-copyright-mobile {
    display: inline;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 6vw 4vw;
    height: 550px;
  }

  .footer-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-logo-section {
    align-items: flex-start;
    text-align: left;
  }

  .footer-logo {
    margin-top: 10vw;
    width: 8vw;
    height: 10vw;
  }

  .footer-tagline {
    color: var(--Neutral-400, #8fa3cb);
    font-feature-settings: "clig" off, "liga" off;
    font-family: "IBM Plex Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 10vw;
  }

  .footer-nav {
    font-size: 3.33vw;
    line-height: 5vw;
    text-align: left;
  }

  .footer-nav ul {
    align-items: flex-start;
  }

  .footer-nav li {
    margin-bottom: 7.5vw;
  }

  .footer-privacy {
    color: var(--Neutral-White, #fff);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .footer-copyright {
    font-size: 3.33vw;
    line-height: 5vw;
  }

  .footer-copyright-desktop {
    display: none;
  }

  .footer-copyright-mobile {
    display: inline;
  }
  .footer-nav li:last-child {
    margin-bottom: 5vw;
  }
}
