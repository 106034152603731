.about-us-section {
  background: url("/src/assets/image_5_desktop.png") lightgray 50% / cover no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--Neutral-White, #fff);
  display: flex;
  width: auto;
  height: 600px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  max-width: 2460px;
  max-height: 1080px;
  margin: 0 auto;
}

.who-we-are-wrapper {
  width: 100%;
}

@media (max-width: 480px) {
  .about-us-section {
    background: url("/src/assets/image_5_mobile.png") lightgray 50% / cover
      no-repeat;
    display: flex;
    height: auto;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    min-height: 70vh;
  }
}
