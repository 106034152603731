.who-we-are-section {
    background-color: #2D3047;
    width: 100%;
    padding: 104px 130px;
    box-sizing: border-box;
    color: white;
    display: flex;
    align-items: center;
    max-width: 2460px;
    max-height: 1080px;
    margin: 0 auto;
}

.who-we-are-content {
    width: 100%;
    text-align: left;
}

.who-we-are-section h2 {
    margin-top: .1vw;
    color: var(--Neutral-White, #FFF);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Mulish;
    font-size: 67px;
    font-style: normal;
    font-weight: 700;
    line-height: 72px;
    letter-spacing: -1px;
    margin-bottom: 24px;
}

.who-we-are-section p {
    color: var(--Neutral-White, #FFF);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
}

@media (max-width: 768px) {
    .who-we-are-section {
        padding: 40px;
        height: auto;
    }

    .who-we-are-content {
        text-align: left;
    }
}

@media (max-width: 480px) {
    .who-we-are-section {
        padding: 64px 24px; 
        height: auto; 
        min-height: 1260px; 
    }

    .who-we-are-content {
        padding-top: 40px; 
        padding-bottom: 40px; 
    }

}