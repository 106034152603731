.section-2-background {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 104px 130px 104px 730px;
  background-image: url("/src/assets/brett-jordan-92-mTYj5oGs-unsplash_desktop.png");
  background-size: fill;
  background-position: center;
  max-width: 2460px;
  max-height: 1080px;
  margin: 0 auto;
  box-sizing: border-box;
}

.section-2-content {
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0.2vw 0.4vw rgba(0, 0, 0, 0.1);
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  width: 580px;
}

.section-2-number {
  color: var(--Neutral-950, #2d3047);
  font-feature-settings: "clig" off, "liga" off;
  text-align: left;
  font-family: "Space Mono";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.section-2-header {
  color: var(--Neutral-950, #2d3047);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Mulish;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px; 
  letter-spacing: -1px;
  margin-top: 0;
  text-align: left;
}

.section-2-body {
  color: var(--Neutral-950, #2d3047);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; 
  text-align: left;
}

@media (max-width: 768px) {
  .section-2-background {
    padding: 104px 16px;
    background-image: url("/src/assets/brett-jordan-92-mTYj5oGs-unsplash_mobile.png");
  }

  .section-2-content {
    width: calc(100% - 32px); 
    max-width: 396px; 
    margin: 0 auto; 
    padding: 24px;
  }

  .section-2-number {
    font-size: 4vw;
    line-height: 5.6vw;
    padding: 0;
  }

  .section-2-header {
    font-size: 50px;
    line-height: 60px;
    padding: 0;
    text-align: left;
  }

  .section-2-body {
    font-size: 24px;
    line-height: 32px;
    padding: 0;
    text-align: left;
  }
}
