.about-us {
  width: 100%;
  max-width: 2460px;
  padding: 104px 200px;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box;
  overflow-x: auto;
}

.about-us h2 {
  font-family: "Mulish", sans-serif;
  font-size: 71px;
  font-weight: 700;
  line-height: 71px;
  color: #2d3047;
  margin: 0 0 24px 0;
  white-space: nowrap;
  text-shadow: none;
}

.about-us .headline {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  color: var(--Neutral-950, #2d3047);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Mulish;
  font-size: 89px;
  font-style: normal;
  font-weight: 700;
  line-height: 89px;
  letter-spacing: -2px;
  white-space: nowrap;
}

.about-us .headline span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-us .headline .man-photo,
.about-us .headline .city-photo,
.about-us .headline .woman-photo {
  border-radius: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.about-us .headline .man-photo {
  width: 240px;
  height: 98px;
  background: url("/src/assets/image-chip.png") lightgray 50% / cover no-repeat;
}

.about-us .headline .city-photo {
  width: 120px;
  height: 98px;
  background: url("/src/assets/image-chip2.png") lightgray 50% / cover no-repeat;
}

.about-us .headline .woman-photo {
  width: 240px;
  height: 98px;
  background: url("/src/assets/image-chip3.png") lightgray 50% / cover no-repeat;
}

.about-us p {
  font-family: "Mulish", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  color: #2d3047;
  margin: 24px 0;
}

.about-us .about-button {
  color: var(--Neutral-White, #fff);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Space Mono";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: #ffffff;
  background-color: #d62c2f;
  border: none;
  padding: 12px 24px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 24px;
}

.about-us .about-button:hover {
  background-color: #b7282b;
}
@media (max-width: 768px) {
  .about-us {
    display: flex;
    padding: 13.54vw 4vw; 
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    text-align: center;
  }

  .about-us h2 {
    font-size: 50px;
    line-height: 60px;
    white-space: normal;
    letter-spacing: -0.13vw;
    text-align: center;
  }

  .about-us .headline {
    justify-content: center;
    text-align: center;
    font-size: 50px;
    line-height: 89px;
  }

  .about-us .headline .city-photo,
  .about-us .headline .woman-photo {
    width: 10.42vw;
    height: 7.81vw;
    border-radius: 100px;
    background-size: cover;
    background-position: center;
  }

  .about-us .headline .man-photo {
    display: none;
  }

  .about-us .headline .city-photo {
    background: url("/src/assets/image-chip_mobile.png") lightgray 50% / cover
      no-repeat;
  }

  .about-us .headline .woman-photo {
    background: url("/src/assets/image-chip2_mobile.png") lightgray 50% / cover
      no-repeat;
  }

  .about-us p {
    font-size: 20px;
    line-height: 28px;
    text-align: center;
  }

  .about-us .about-button {
    font-size: 2.78vw;
    padding: 1.11vw 2.22vw;
  }
}

@media (max-width: 480px) {
  .about-us {
    padding: 104px 16px; 
    text-align: center;
  }

  .about-us .headline {
    color: var(--Neutral-950, #2d3047);
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Mulish;
    font-size: 12vw;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: -0.5px;
    justify-content: center;
    gap: 10px;
    margin-bottom: 30px;
  }

  .about-us h2 {
    color: var(--Neutral-950, #2d3047);
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Mulish;
    font-size: 12vw;
    font-style: normal;
    font-weight: 700;
    line-height: 0.5;
    letter-spacing: -0.5px;
    max-width: 100%;
  }

  .about-us .headline .city-photo,
  .about-us .headline .woman-photo {
    width: 80px;
    height: 60px;
  }

  .about-us p {
    color: var(--Neutral-950, #2d3047);
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Mulish;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; 
  }

  .about-us .about-button {
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: var(--Neutral-White, #fff);
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Space Mono";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; 
  }
}
