.the-team-section {
  background-color: #ffffff;
  width: 100%;
  padding: 104px 130px;
  box-sizing: border-box;
  color: #2d3047;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4vw;
}

.the-team-content {
  max-width: 1700px;
  width: 100%;
  text-align: left;
}

.the-team-content h2 {
  color: #2d3047;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Mulish;
  font-size: 67px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: -1px;
  margin-bottom: 24px;
}

.team-member {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  margin-bottom: 40px;
  max-width: 100%;
}

.team-member.reverse {
  flex-direction: row-reverse;
  /* Reverse the order of the image and text */
}

.team-member .image {
  flex-shrink: 0;
  width: 277px;
  height: 320px;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.team-member .image-1 {
  background: url(/src/assets/steve.png) lightgray 50% / cover no-repeat;
}

.team-member .image-2 {
  background: url(/src/assets/darren.png) lightgray 50% / cover no-repeat;
}

.team-member .image-3 {
  background: url(/src/assets/andrew.png) lightgray 50% / cover no-repeat;
}

.team-member .text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: calc(100% - 266px);
  /* Ensure the text content does not overflow the container */
  height: 274px;
  border: 1px solid #ddd;
  padding: 16px;
  border-radius: 8px;
}

.the-team-content h3 {
  color: var(--Neutral-950, #2D3047);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Mulish;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -1px;
  margin-bottom: -30px;
}

.the-team-content h4 {
  color: var(--Neutral-950, #2D3047);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 0px;
}

.the-team-content p {
  color: var(--Neutral-950, #2d3047);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

@media (max-width: 768px) {
  .the-team-section {
    padding: 10vw;
    height: auto;
  }

  .the-team-content {
    text-align: left;
  }

  .team-member {
    flex-direction: column;
    align-items: center;
  }

  .team-member.reverse {
    flex-direction: column-reverse;
  }

  .team-member .text-content {
    text-align: left;
    align-items: left;
    max-width: 100%;
    height: auto;
  }
}

@media (max-width: 480px) {
  .team-member .image {
    display: flex;
    width: 85vw;
    height: 68.75vw;
    min-height: 68.75vw;
    padding: 5vw;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 3vw;
  }

  .team-member .image-1 {
    background: url(/src/assets/steve_mobile.png) lightgray 50% / cover no-repeat;
  }

  .team-member .image-2 {
    background: url(/src/assets/darren_mobile.png) lightgray 50% / cover no-repeat;
  }

  .team-member .image-3 {
    background: url(/src/assets/andrew_mobile.png) lightgray 50% / cover no-repeat;
  }

  .team-member .text-content {
    max-width: 100vw;
    width: 92vw;
  }

  .team-member.reverse {
    flex-direction: column;
  }

  .the-team-content h3 {
    color: var(--Neutral-950, #2d3047);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Mulish;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -1px;
    margin-bottom: -30px;
    margin-top: 32px;
  }

  .the-team-content h2 {
    color: var(--Neutral-950, #2d3047);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Mulish;
    font-size: 67px;
    font-style: normal;
    font-weight: 700;
    line-height: 72px;
    letter-spacing: -1px;
    margin-bottom: 5vw;
    text-align: left;
  }

  .the-team-content h4 {
    color: var(--Neutral-950, #2d3047);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }

  .the-team-content p {
    color: var(--Neutral-950, #2d3047);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
}