.section.homepage {
  background: url(/src/assets/hero_image_desktop.png) lightgray 0px -67.109px / 121.345%
    146.407% no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  color: var(--Neutral-White, #fff);
  padding: 160px 530px 160px 130px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  height: 714px;
  background-size: fill;
  max-width: 2460px;
  max-height: 1080px;
  margin: 0 auto;
}

.headline-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  gap: 16px;
  padding: 0;
  box-sizing: border-box;
  width: 640px;
}

.headline {
  align-self: stretch;

  color: var(--Neutral-White, #fff);
  font-feature-settings: "clig" off, "liga" off;
  text-shadow: 0px 0px 150px #d62c2f;

  font-family: Mulish;
  font-size: 89px;
  font-style: normal;
  font-weight: 900;
  line-height: 89px;
  letter-spacing: -2px;
  text-align: left;
  margin-bottom: 0px;
}

.headline-description {
  color: var(--Neutral-White, #fff);
  font-feature-settings: "clig" off, "liga" off;
  text-shadow: 0px 0px 80px #d62c2f;

  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: #000;
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.6vw;
  margin: 0.8vw 0 0 0;
  line-height: 1.5;
  text-align: left;
}

@media (max-width: 768px) {
  .section.homepage {
    flex-direction: column;
    height: auto;
    background-position: center;
    padding: 8vw 4vw;
  }

  .headline-container {
    width: 100%;
    max-width: 100%;
    align-items: flex-start;
  }

  .headline {
    color: var(--Neutral-White, #fff);
    font-feature-settings: "clig" off, "liga" off;
    text-shadow: 0px 0px 150px #d62c2f;
    font-family: Mulish;
    font-size: 8.7vw;
    font-style: normal;
    font-weight: 900;
    line-height: 9.4vw;
    letter-spacing: -0.05vw;
    text-align: left;
  }

  .headline-description {
    color: var(--Neutral-White, #fff);
    font-feature-settings: "clig" off, "liga" off;
    text-shadow: 1vw 0.5vw 5.7vw #000;
    font-family: "IBM Plex Sans";
    font-size: 4vw;
    font-style: normal;
    font-weight: 400;
    line-height: 5.1vw;
    text-align: left;
  }
}

@media (max-width: 480px) {
  .section.homepage {
    padding: 160px 24px;
    height: 712px;
    background-image: url(/src/assets/hero_image_mobile.png);
  }

  .headline {
    color: var(--Neutral-White, #fff);
    font-feature-settings: "clig" off, "liga" off;
    text-shadow: 0px 0px 150px #d62c2f;
    font-family: Mulish;
    font-size: 67px;
    font-style: normal;
    font-weight: 900;
    line-height: 72px;
    letter-spacing: -1px;
  }

  .headline-description {
    color: var(--Neutral-White, #fff);
    font-feature-settings: "clig" off, "liga" off;
    text-shadow: 0px 0px 80px #d62c2f;
    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }
}
