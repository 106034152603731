.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 80px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  height: 80px;
  box-sizing: border-box;
  max-width: 2460px;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.header.visible {
  transform: translateY(0);
}

.header.hidden {
  transform: translateY(-100%);
}

.header .logo {
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.header .logo img {
  height: 40px;
  width: auto;
}

.header .nav-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}

.header .nav-wrapper nav {
  display: flex;
  align-items: center;
}

.header .nav-wrapper nav ul {
  display: flex;
  gap: 20px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.header .nav-wrapper nav ul li {
  display: flex;
  align-items: center;
}

.header .nav-wrapper nav ul li a {
  text-decoration: none;
  color: var(--white);
  font-weight: 400;
  font-family: "Space Mono", monospace;
  font-size: 16px;
  line-height: 1.5;
  padding: 8px 16px;
}

.menu-icon {
  display: none;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  width: 30px;
}

.menu-icon .line {
  width: 100%;
  height: 2px;
  background-color: var(--white);
}

@media (max-width: 768px) {
  .header {
    padding: 20px;
    height: auto;
  }

  .header .logo img {
    height: 30px;
  }

  .header .nav-wrapper nav {
    display: none;
  }

  .header .nav-wrapper nav.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    background: var(--dark-background);
    padding: 20px;
    border-radius: 8px;
  }

  .header .nav-wrapper nav ul {
    flex-direction: column;
    gap: 10px;
  }

  .header .nav-wrapper nav ul li a {
    font-size: 14px;
    padding: 8px;
  }

  .menu-icon {
    display: flex;
    padding-bottom: 10px;
  }
}